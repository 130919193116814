/* ABILITIES */
.abilities-flex-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skills-icon-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.skills-icon-container i {
  margin: 5px 10px;
}


/* ------------------------------MEDIA QUERIES---------------------------- */

/* ------------------------------550px---------------------------------- */
@media (min-width: 550px) {

  /* ABILITIES */
  .abilities-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 15px;
  }

  .education ul {
    text-align: left;
  }
}