/* Variables */
:root {
  --heading-font: 'Philosopher', sans-serif;
  --body-font: 'Muli', sans-serif;
  --quote-font: 'Merriweather', serif;

}
.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: var(body-font);
}

h2,
p,
h1,
h3,
ul {
  margin: 0;
}

h1 {
  padding-bottom: 20px;
  margin-top: 160px;
  font-size: 16vw;
}

h2 {
  font-size: 50px;
  margin-top: 70px;
  padding: 10px;
  color: white;
  text-shadow: 5px 5px 8px black;
}

h3 {
  font-size: 23px;
  padding: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  padding-top: 5px;
  padding-bottom: 10px
}

li,
p {
  font-size: 16px;
  line-height: 24px;
}

p {
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
  padding-bottom: 10px;
}

section {
  background-color: white;
  min-height: 100px;
}

header {
  margin-bottom: 0;
  font-family: 'Philosopher', sans-serif;
}

footer {
  background-color: rgb(46, 46, 46);
  color: white;
  text-align: center;
  padding: 5px;
  margin-bottom: 50px;
}

/* ------------------------------MEDIA QUERIES---------------------------- */

/* ------------------------------550px---------------------------------- */
@media (min-width: 550px) {
  main {
    font-size: 20px;
  }

  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 60px;
  }

  h3 {
    font-size: 27px;
  }

  footer {
    margin-bottom: 0px;
  }
}

/* ------------------------------800px---------------------------------- */

@media (min-width: 800px) {
  p {
    font-size: 20px;
  }
}

/* ------------------------------1400px---------------------------------- */

@media (min-width: 1400px) {

  .App {
    width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    margin: 0 auto;
  }
}
